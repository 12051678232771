import { AbstractControl } from '@angular/forms';

import { tap } from 'rxjs/operators';

import { retailer } from '../../rxjs-operators';
import { RetailerService } from '../../services/entities/retailer.service';
import { UserService } from '../../services/entities/user.service';





export const addRetailerSearchFormLogic = (
  retailerCode: AbstractControl,
  retailerName: AbstractControl,
  retailerService: RetailerService,
  userService: UserService,
  
) =>
  retailerCode.valueChanges
    .pipe(tap(v => !!v && retailerCode.setErrors({ loading: true })), retailer(retailerService))
    .subscribe(r => {
      if(r.result!=null){
        const data_result =r.result;
        const name_result=r.result.name;
        userService.getRetailer(r.result.code).subscribe(r => {
          if(r.length>=2){
              retailerCode.setErrors({ notAvailable: true });
              retailerName.setValue('');
          }
          else{
            retailerName.setValue( data_result && name_result);
            retailerCode.setErrors(null);
          }
          

         

        });
        
      }
      else{
        console.log(r.error);
        retailerCode.setErrors(r.error);
        retailerName.setValue('');
      }
      
    });
