import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, concatMap, catchError } from 'rxjs/operators';

import { RetailerService } from '../services/entities/retailer.service';
import { Retailer } from '../models/entities/retailer';

export const retailer = (retailerService: RetailerService) => (
  source: Observable<string>,
): Observable<{ result: Retailer | null; error: { notFound: boolean } | null }> =>
  source.pipe(
    debounceTime(300),
    distinctUntilChanged<string>(),
    concatMap(code =>
      retailerService
        .getByCode(code)
        .pipe(
          map(v => ({ result: v, error: null })),
          catchError(err => of({ result: null, error: { notFound: true } })),
        ),
    ),
  );
