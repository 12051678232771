import { Injectable } from '@angular/core';

import { catchError, map } from 'rxjs/operators';
import { empty } from 'rxjs';

import { ApiService } from '../../../core/services/http/api.service';
import { environment } from '../../../../environments/environment';
import { Product } from '../../models/entities/order';
import { PageQuery } from '../../models/pagination/page-query';
import { RequestService } from '../../../core/services/http/request.service';
import { PageResult } from '../../models/pagination/page-result';

@Injectable()
export class ProductsService {
  private urlApi: string;

  constructor(private api: ApiService, private request: RequestService) {
    this.urlApi = `${environment.apiUrl}/products`;
  }

  get = (id: number) => this.api.get<Product>(`${this.urlApi}/${id}`);

  getAll = (query?: Partial<Product> & Partial<PageQuery>) =>
    this.api
      .get<Product[]>(this.urlApi, {
        params: this.request.getParams(query || {}),
        observe: 'response',
      })
      .pipe(
        map(
          resp =>
            ({
              result: resp.body,
              count: Number(resp.headers.get('X-Total-Count')),
            } as PageResult<Product>),
        ),
      );
}
